<template lang="pug">
div
  .blocks
    .small-block(
      v-for="type in automaticTypes"
      :key="type.key"
      :class="{ 'active-block': coupon.automatic.type === type.value }"
      @click="toggleAutomaticType(type.value)"
    )
      .small-block-icon(:class="{ [type.key]: true }")
        component(:is="type.svg")
      .small-block-title
        span {{ $t(`discountPane.automatic.${type.key}`) }}
  template(v-if="coupon.automatic.type === 'percentage'")
    OmInput#percentage(
      :label="$t('discountPane.automatic.percentageValue')"
      v-model="percentage"
      placeholder="e.g. 10"
      small
    )
      template(slot="error" v-if="isPercentageValueInvalid")
        span {{ $t(validationError.msg) }}
  template(v-else)
    OmInput#fixed-value(
      :label="$t('discountPane.automatic.fixedValue', { currency })"
      v-model="fixedValue"
      placeholder="e.g. 10"
      small
    )
  .mt-1
    OmInput#prefix(
      :label="$t('discountPane.automatic.prefix')"
      v-model="prefix"
      placeholder="e.g. BLACKFRIDAY"
      small
    )
  Heading.mt-3(expandableGap="8px" :open="expiration")
    template(#titleTrailing)
      .font-weight-bold {{ $t('discountPane.automatic.expiration') }}
    template(#prependEnding)
      OmSwitch#expiration-switch(v-model="expiration")
    template(#expandableContent)
      .radio-holder.mt-3
        .d-flex.gap-2
          input#radio-absolute(
            type="radio"
            name="coupon-expiration-type"
            value="absolute"
            v-model="expirationType"
          )
          label.radio-label(for="radio-absolute") {{ $t('discountPane.automatic.fixedExpiration') }}
        .inputs-wrapper.flex-column(v-if="isAbsoluteExpiration")
          .date-picker
            v-date-picker.form-control.d-flex.brand-date-picker(
              mode="single"
              :min-date="new Date()"
              :formats="{ title: 'MMMM YYYY', weekdays: 'W', navMonths: 'MMM', input: [dateFormat], dayPopover: shortDateFormat }"
              v-model="expiresDate"
              :class="{ 'is-invalid': isExpiresDateInValid }"
            )
              template(v-slot="{ inputValue, inputEvents }")
                input(:value="inputValue" v-on="inputEvents")
                .brand-date-picker-icon
                  fa-icon(variant="fa-calendar-o" size="1.5")
          .d-flex.flex-column
            .d-flex.flex-row
              Dropdown#expires-hour.time-input(
                v-model="expiresHour"
                :items="hours"
                :error="isExpiresDateInValid"
              )
              span.d-flex.align-items-center.my-auto :
              Dropdown#expires-minute.time-input(
                v-model="expiresMinute"
                :items="minutes"
                :error="isExpiresDateInValid"
              )
            .text-danger(v-if="isExpiresDateInValid") {{ $t(validationError.msg) }}
        .sub-title.mb-3(
          v-if="isAbsoluteExpiration"
          v-html="$t('discountPane.automatic.fixedSubTitle', { offset: getDiffToGMT() })"
        )
        .d-flex.gap-2
          input#radio-relative(
            type="radio"
            name="coupon-expiration-type"
            value="relative"
            v-model="expirationType"
          )
          label.radio-label(for="radio-relative") {{ $t('discountPane.automatic.afterDisplayExpiration') }}
        .inputs-wrapper(v-if="!isAbsoluteExpiration")
          OmInput#expires-is(v-model="expiresIn" small placeholder="e.g. 15")
            template(slot="error" v-if="isExpiresInInvalid")
              span {{ $t(validationError.msg) }}
          Dropdown#expires-in-units(:items="expiresInUnits" v-model="expiresInUnitValue")
</template>
<script>
  import { mapState } from 'vuex';
  import moment from 'moment';
  import GET_SHOP_INFO from '@/graphql/GetEcommerceShopInfo.gql';
  import dateFormatMixin from '@/mixins/dateFormat';
  import sharedMixin from '@/components/Editor/Blocks/Coupon/shared';
  import itemMixin from '@/editor/mixins/item';
  import PercentageSVGVue from '@/editor/components/svg/PercentageSVG.vue';
  import MoneyFixedSVGVue from '@/editor/components/svg/MoneyFixedSVG.vue';
  import DateTimePicker from '@/components/DateTimepicker';
  import Heading from '../../Heading.vue';
  import Dropdown from '../../Dropdown/Dropdown.vue';

  export default {
    components: {
      DateTimePicker,
      Heading,
      Dropdown,
    },
    mixins: [dateFormatMixin, sharedMixin, itemMixin],
    data() {
      return {
        moment,
        currency: '',
        hours: Array.from({ length: 24 }, (_, i) => ({ value: i, text: this.timeUnitFormat(i) })),
        minutes: Array.from({ length: 60 }, (_, i) => ({ value: i, text: this.timeUnitFormat(i) })),
        automaticTypes: [
          { key: 'percentage', value: 'percentage', svg: PercentageSVGVue },
          { key: 'fixed', value: 'fixed', svg: MoneyFixedSVGVue },
        ],
      };
    },
    computed: {
      ...mapState(['validationError']),
      percentage: {
        get() {
          return this.getValueOf('data.coupon.automatic.percentageValue');
        },
        set(value) {
          this.setValueOf('data.coupon.automatic.percentageValue', value);
        },
      },
      fixedValue: {
        get() {
          return this.getValueOf('data.coupon.automatic.fixedValue');
        },
        set(value) {
          this.setValueOf('data.coupon.automatic.fixedValue', value);
        },
      },
      prefix: {
        get() {
          return this.getValueOf('data.coupon.automatic.prefix');
        },
        set(value) {
          this.setValueOf('data.coupon.automatic.prefix', value);
        },
      },
      expiration: {
        get() {
          return this.getValueOf('data.coupon.automatic.expiration');
        },
        set(value) {
          this.setValueOf('data.coupon.automatic.expiration', value);
        },
      },
      expirationType: {
        get() {
          return this.coupon.automatic.expirationType;
        },
        set(value) {
          this.setValueOf('data.coupon.automatic.expirationType', value);
        },
      },
      expiresDate: {
        get() {
          return new Date(this.coupon.automatic.expirationTime);
        },
        set(v) {
          const newDate = moment(v);
          const expirationTime = moment(this.coupon.automatic.expirationTime);
          expirationTime.year(newDate.year());
          expirationTime.month(newDate.month());
          expirationTime.date(newDate.date());
          this.setExpirationTime(expirationTime);
        },
      },
      expiresHour: {
        get() {
          return moment(this.coupon.automatic.expirationTime).hour();
        },
        set(v) {
          const expirationTime = moment(this.coupon.automatic.expirationTime);
          expirationTime.hour(v);
          this.setExpirationTime(expirationTime);
        },
      },
      expiresMinute: {
        get() {
          return moment(this.coupon.automatic.expirationTime).minute();
        },
        set(v) {
          const expirationTime = moment(this.coupon.automatic.expirationTime);
          expirationTime.minute(v);
          this.setExpirationTime(expirationTime);
        },
      },
      expiresIn: {
        get() {
          return this.getValueOf('data.coupon.automatic.expiresIn');
        },
        set(value) {
          this.setValueOf('data.coupon.automatic.expiresIn', value);
        },
      },
      expiresInUnitValue: {
        get() {
          return this.getValueOf('data.coupon.automatic.expiresInUnit');
        },
        set(value) {
          this.setValueOf('data.coupon.automatic.expiresInUnit', value);
        },
      },
      isAbsoluteExpiration() {
        return this.expirationType === 'absolute';
      },
      isExpiresDateInValid() {
        return (
          this.isAbsoluteExpiration &&
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('data.coupon.automatic.expirationTime')
        );
      },
      isExpiresInInvalid() {
        return (
          !this.isAbsoluteExpiration &&
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('data.coupon.automatic.expiresIn')
        );
      },
      isPercentageValueInvalid() {
        return (
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('data.coupon.automatic.percentageValue')
        );
      },
    },
    mounted() {
      if (this.isActiveShopify) {
        this.getShopifyShopCurrency();
      }
    },
    methods: {
      toggleAutomaticType(type) {
        this.setValueOf('data.coupon.automatic.type', type);
      },
      setExpirationTime(time) {
        this.setValueOf('data.coupon.automatic.expirationTime', time.toISOString());
      },
      async getShopifyShopCurrency() {
        const { data } = await this.$apollo.query({
          query: GET_SHOP_INFO,
          variables: { type: 'shopify', domain: this.campaign.domain },
        });

        if (data && data.getShopInfo) {
          const { moneyFormat } = data.getShopInfo;
          this.currency = moneyFormat.replace(/{{.*amount.*}}/gi, '').trim();
        }
      },
    },
  };
</script>
<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .blocks
    display: flex
    .small-block
      height: 60px
      width: 120px
      border-radius: 4px
      border: 1px solid $om-gray-400
      cursor: pointer
      &:last-of-type
        margin-left: 8px
      &-icon
        margin-top: 12px
        margin-bottom: 8px
        display: flex
        justify-content: center
      &-title
        display: flex
        justify-content: center
        color: $om-gray-700
        font-size: 0.625rem
        line-height: 1rem
    .active-block
      height: 60px
      width: 120px
      border-radius: 4px
      border: 1px solid $om-orange-500
  .brand-date-picker-icon
    position: absolute
    right: 8px
    top: 9px
  .brand-date-picker
    min-height: 40px
    font-size: 13px
    padding: 0 !important
    margin-right: 5px
    width: 100%
    input
      height: 40px
      padding: 4px 8px
      width: 100%
      border: 0
      background: transparent
  .radio-holder
    display: flex
    flex-direction: column
    gap: 12px
    .radio-label
      margin-top: 2px
      font-size: 0.75rem
      color: $om-gray-700
      margin-left: 8px
  .date-time-holder
    margin-left: -0.7rem
    .time-input
      width: 60px !important
      ::v-deep .epic-simple-input
        position: relative
      ::v-deep select
        font-size: 12px !important
        height: 40px !important
        width: 50px !important
        padding-left: 5px
      ::v-deep .select-arrow
        top: 9px !important
        right: 10px !important
      ::v-deep .sidebar-title-tooltip-container
        display: none
    .expires-in-unit-input
      width: 100%
      ::v-deep .epic-simple-input
        width: 100%
        .select-arrow
          top: 1rem
          right: 1.5rem
      ::v-deep select
        width: 100%
        height: 40px !important
    .validation-failed ::v-deep select
      background: rgba(255, 0, 0, 0.1) !important
      border-color: #dc3545 !important
      option
        background: white
    span
      max-width: 5px
  input[type='radio']
    width: 22px
    height: 20px
  input[type="radio"]:after
    content: url(~@/assets/editor/svg/RadioCheckBox.svg)
  input[type='radio']:checked:after
    width: 22px
    height: 20px
    content: url(~@/assets/editor/svg/RadioCheckBoxChecked.svg)
  .gap-2
    gap: 8px
  .inputs-wrapper
    display: flex
    gap: 12px
    .input-wrapper
      flex: 0 1 35%
      margin-block: auto
    .ds-dropdown
      flex: 1
    .flex-row
      gap: 4px
</style>
